import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function CardBasicPreview(props) {
  const { CardBasic } = props
  return (
    <>
      <CopyComponent copyToClipboardCode={CardBasic} componentName="CardBasic">
        {/*<!-- Component: Basic card --> */}
        <div className="overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
          <div className="p-6">
            <h3 className="mb-4 text-xl font-medium text-slate-700">
              Something to remember
            </h3>
            <p>
              All components can be copied and pasted and easily implemented in
              your tailwind css projects. You can either copy all the components
              in the preview window from the copy icon on the top right corner
              in the code blocks or just hover and click on the one component
              you need and paste it on your project.
            </p>
          </div>
        </div>
        {/*<!-- End Basic card --> */}
      </CopyComponent>
    </>
  )
}
