import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function CardBlogAvatarPreview(props) {
  const { CardBlogAvatar } = props

  return (
    <>
      <CopyComponent
        copyToClipboardCode={CardBlogAvatar}
        componentName="CardBlogAvatar"
      >
        {/*<!-- Component: Blog card with avatar --> */}
        <div className="overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
          {/*  <!-- Image --> */}
          <figure>
            <img
              src="https://picsum.photos/id/114/800/600"
              alt="card image"
              className="w-full aspect-video"
            />
          </figure>
          {/*  <!-- Body--> */}
          <div className="p-6">
            <header className="flex gap-4 mb-4">
              <a
                href="#"
                className="relative inline-flex items-center justify-center w-12 h-12 text-white rounded-full"
              >
                <img
                  src="https://i.pravatar.cc/48?img=24"
                  alt="user name"
                  title="user name"
                  width="48"
                  height="48"
                  className="max-w-full rounded-full"
                />
              </a>
              <div>
                <h3 className="text-xl font-medium text-slate-700">
                  Stairing at the sky
                </h3>
                <p className="text-sm text-slate-400">
                  By Sue Longarm, jun 3 2023
                </p>
              </div>
            </header>
            <p>
              Following the journey of history and culture, is a journey through
              the beautiful green rice paddies, and endless golden fields!
              Dotted amongst the countryside are bustling towns, with a vibrant
              atmosphere and great cafe culture.
            </p>
          </div>
        </div>
        {/*<!-- End Blog card with avatar --> */}
      </CopyComponent>
    </>
  )
}
