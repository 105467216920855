import React, { useState } from "react"
import { Helmet } from "react-helmet"
// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/cards/windui-cards-promo.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
import CardHeroComponent from "../../../library/components/card/react/_preview/CardHeroComponent"

// Preview
import CardEmptyPreview from "../../../library/components/card/react/_preview/empty"
import CardBasicPreview from "../../../library/components/card/react/_preview/basic"
import CardIconPreview from "../../../library/components/card/react/_preview/icon"
import CardSubtitlePreview from "../../../library/components/card/react/_preview/subtitle"
import CardImagePreview from "../../../library/components/card/react/_preview/image"
import CardBlogPreview from "../../../library/components/card/react/_preview/blog"
import CardBlogAvatarPreview from "../../../library/components/card/react/_preview/blog_avatar"
import CardBlogActionPreview from "../../../library/components/card/react/_preview/blog_action"
import CardEcommercePreview from "../../../library/components/card/react/_preview/ecommerce"
import CardSocialPreview from "../../../library/components/card/react/_preview/social"
import CardUserProfilePreview from "../../../library/components/card/react/_preview/user_profile"
import CardFormPreview from "../../../library/components/card/react/_preview/form"
import CardImageOverlayPreview from "../../../library/components/card/react/_preview/image_overlay"
import CardHorizontalPreview from "../../../library/components/card/react/_preview/horizontal"

// All
// Empty card
const CardEmptyJsx = require("!!raw-loader!../../../library/components/card/react/empty.jsx")
const CardEmptyHTML = require("!!raw-loader!../../../library/components/card/html/empty.html")
// Card with title and text
const CardBasicJsx = require("!!raw-loader!../../../library/components/card/react/basic.jsx")
const CardBasicHTML = require("!!raw-loader!../../../library/components/card/html/basic.html")
// Card with icon, title and text
const CardIconJsx = require("!!raw-loader!../../../library/components/card/react/icon.jsx")
const CardIconHTML = require("!!raw-loader!../../../library/components/card/html/icon.html")
// Card with title & Subtitle subtitle
const CardSubtitleJsx = require("!!raw-loader!../../../library/components/card/react/subtitle.jsx")
const CardSubtitleHTML = require("!!raw-loader!../../../library/components/card/html/subtitle.html")
// Card with image, title & subtitle
const CardImageJsx = require("!!raw-loader!../../../library/components/card/react/image.jsx")
const CardImageHTML = require("!!raw-loader!../../../library/components/card/html/image.html")
// Card with image,title, subtitle and text
const CardBlogJsx = require("!!raw-loader!../../../library/components/card/react/blog.jsx")
const CardBlogHTML = require("!!raw-loader!../../../library/components/card/html/blog.html")
// Card with image, title, subtitle, user avatar and text
const CardBlogAvatarJsx = require("!!raw-loader!../../../library/components/card/react/blog_avatar.jsx")
const CardBlogAvatarHTML = require("!!raw-loader!../../../library/components/card/html/blog_avatar.html")
// Card with image, title, subtitle, user avatar, text and actions
const CardBlogActionsJsx = require("!!raw-loader!../../../library/components/card/react/blog_action.jsx")
const CardBlogActionsHTML = require("!!raw-loader!../../../library/components/card/html/blog_action.html")
// Card with image, title, subtitle, text full width action
const CardCommerceJsx = require("!!raw-loader!../../../library/components/card/react/ecommerce.jsx")
const CardCommerceHTML = require("!!raw-loader!../../../library/components/card/html/ecommerce.html")
// Card with header above, image, text and icons bellow
const CardSocialJsx = require("!!raw-loader!../../../library/components/card/react/social.jsx")
const CardSocialHTML = require("!!raw-loader!../../../library/components/card/html/social.html")
// Card with avatar, title, subtitle and action
const CardUserProfileJsx = require("!!raw-loader!../../../library/components/card/react/user_profile.jsx")
const CardUserProfileHTML = require("!!raw-loader!../../../library/components/card/html/user_profile.html")
// Card with form
const CardFormJsx = require("!!raw-loader!../../../library/components/card/react/form.jsx")
const CardFormHTML = require("!!raw-loader!../../../library/components/card/html/form.html")
// Card with image and overlay, title & subtitle
const CardImageOverlayJsx = require("!!raw-loader!../../../library/components/card/react/image_overlay.jsx")
const CardImageOverlayHTML = require("!!raw-loader!../../../library/components/card/html/image_overlay.html")
// Horizontal card with image, title, subtitle, user avatar and text
const CardHorizontalJsx = require("!!raw-loader!../../../library/components/card/react/horizontal.jsx")
const CardHorizontalHTML = require("!!raw-loader!../../../library/components/card/html/horizontal.html")

export default function CardsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "CardBasic",
      title: "Basic Card",
      active_tab: 1,
    },
    {
      component_name: "CardIcon",
      title: "With icon",
      active_tab: 1,
    },
    {
      component_name: "CardSubtitle",
      title: "With subtitle",
      active_tab: 1,
    },
    {
      component_name: "CardImage",
      title: "With image",
      active_tab: 1,
    },
    {
      component_name: "CardBlog",
      title: "Blog",
      active_tab: 1,
    },
    {
      component_name: "CardBlogAvatar",
      title: "Blog, avatar",
      active_tab: 1,
    },
    {
      component_name: "CardBlogAction",
      title: "Blog, action button",
      active_tab: 1,
    },
    {
      component_name: "CardEcommerce",
      title: "E-commerce",
      active_tab: 1,
    },
    {
      component_name: "CardSocial",
      title: "Social story",
      active_tab: 1,
    },
    {
      component_name: "CardUserProfile",
      title: "User profile",
      active_tab: 1,
    },
    {
      component_name: "CardForm",
      title: "With form",
      active_tab: 1,
    },
    {
      component_name: "CardImageOverlay",
      title: "Image overlay",
      active_tab: 1,
    },
    {
      component_name: "CardHorizontal",
      title: "Horizontal",
      active_tab: 1,
    },
    {
      component_name: "CardEmpty",
      title: "Empty",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Cards - WindUI Component Library"
        ogtitle="Tailwind CSS Cards - WindUI Component Library"
        description="A card component can be used to display content related to a single subject, that can consist of multiple elements. Built with Tailwind CSS by WindUI."
        ogdescription="A card component can be used to display content related to a single subject, that can consist of multiple elements. Built with Tailwind CSS by WindUI."
        url="components/cards/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Cards, Cards, Card Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <Helmet
        style={[
          {
            cssText: `
              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                -webkit-text-fill-color: rgb(100, 116, 139);
              }
            `,
          },
        ]}
      />

      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Cards</h1>
          <p>
            A card can be used to display content related to a single subject.
            The content can consist of multiple elements of varying types and
            sizes.
          </p>

          <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <div className="max-w-2xl">
              <section
                className="not-prose max-w-full"
                aria-multiselectable="false"
              >
                <CardHeroComponent />
              </section>
            </div>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="CardBasic">Basic Card</h3>

          <PreviewBlock
            id="CardBasic"
            HtmlComponent={CardBasicHTML.default}
            JsxComponent={CardBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardBasicPreview
                  CardBasic={
                    activeTabs[0].active_tab === 1
                      ? CardBasicHTML.default
                      : CardBasicJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardIcon">Card with Icon</h3>

          <PreviewBlock
            id="CardIcon"
            HtmlComponent={CardIconHTML.default}
            JsxComponent={CardIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardIconPreview
                  CardIcon={
                    activeTabs[1].active_tab === 1
                      ? CardIconHTML.default
                      : CardIconJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardSubtitle">Card with Subtitle</h3>

          <PreviewBlock
            id="CardSubtitle"
            HtmlComponent={CardSubtitleHTML.default}
            JsxComponent={CardSubtitleJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardSubtitlePreview
                  CardSubtitle={
                    activeTabs[2].active_tab === 1
                      ? CardSubtitleHTML.default
                      : CardSubtitleJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardImage">Basic Image Card</h3>

          <PreviewBlock
            id="CardImage"
            HtmlComponent={CardImageHTML.default}
            JsxComponent={CardImageJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardImagePreview
                  CardImage={
                    activeTabs[3].active_tab === 1
                      ? CardImageHTML.default
                      : CardImageJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardBlog">Basic Blog Card</h3>

          <PreviewBlock
            id="CardBlog"
            HtmlComponent={CardBlogHTML.default}
            JsxComponent={CardBlogJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardBlogPreview
                  CardBlog={
                    activeTabs[4].active_tab === 1
                      ? CardBlogHTML.default
                      : CardBlogJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardBlogAvatar">Blog Card with Avatar</h3>

          <PreviewBlock
            id="CardBlogAvatar"
            HtmlComponent={CardBlogAvatarHTML.default}
            JsxComponent={CardBlogAvatarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardBlogAvatarPreview
                  CardBlogAvatar={
                    activeTabs[5].active_tab === 1
                      ? CardBlogAvatarHTML.default
                      : CardBlogAvatarJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardBlogAction">Blog Card with Action Button</h3>

          <PreviewBlock
            id="CardBlogAction"
            HtmlComponent={CardBlogActionsHTML.default}
            JsxComponent={CardBlogActionsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardBlogActionPreview
                  CardBlogAction={
                    activeTabs[6].active_tab === 1
                      ? CardBlogActionsHTML.default
                      : CardBlogActionsJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardEcommerce">E-commerce Card</h3>

          <PreviewBlock
            id="CardEcommerce"
            HtmlComponent={CardCommerceHTML.default}
            JsxComponent={CardCommerceJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardEcommercePreview
                  CardEcommerce={
                    activeTabs[7].active_tab === 1
                      ? CardCommerceHTML.default
                      : CardCommerceJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardSocial">Social Story Card</h3>

          <PreviewBlock
            id="CardSocial"
            HtmlComponent={CardSocialHTML.default}
            JsxComponent={CardSocialJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardSocialPreview
                  CardSocial={
                    activeTabs[8].active_tab === 1
                      ? CardSocialHTML.default
                      : CardSocialJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardUserProfile">User Profile Card</h3>

          <PreviewBlock
            id="CardUserProfile"
            HtmlComponent={CardUserProfileHTML.default}
            JsxComponent={CardUserProfileJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardUserProfilePreview
                  CardUserProfile={
                    activeTabs[9].active_tab === 1
                      ? CardUserProfileHTML.default
                      : CardUserProfileJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardForm">Card with Form</h3>

          <PreviewBlock
            id="CardForm"
            HtmlComponent={CardFormHTML.default}
            JsxComponent={CardFormJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardFormPreview
                  CardForm={
                    activeTabs[10].active_tab === 1
                      ? CardFormHTML.default
                      : CardFormJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardImageOverlay">Image Overlay Card</h3>

          <PreviewBlock
            id="CardImageOverlay"
            HtmlComponent={CardImageOverlayHTML.default}
            JsxComponent={CardImageOverlayJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardImageOverlayPreview
                  CardImageOverlay={
                    activeTabs[11].active_tab === 1
                      ? CardImageOverlayHTML.default
                      : CardImageOverlayJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardHorizontal">Horizontal Card</h3>
          <p>
            On small screens the horizontal card turns to a normal card. Resize
            your browser to see the card's behavior on small screens.
          </p>
          <PreviewBlock
            id="CardHorizontal"
            HtmlComponent={CardHorizontalHTML.default}
            JsxComponent={CardHorizontalJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-2xl">
                <CardHorizontalPreview
                  CardHorizontal={
                    activeTabs[12].active_tab === 1
                      ? CardHorizontalHTML.default
                      : CardHorizontalJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>

          <h3 id="CardEmpty">Empty Card</h3>

          <PreviewBlock
            id="CardEmpty"
            HtmlComponent={CardEmptyHTML.default}
            JsxComponent={CardEmptyJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 sm:p-16 ">
              <div className="max-w-sm">
                <CardEmptyPreview
                  CardEmpty={
                    activeTabs[13].active_tab === 1
                      ? CardEmptyHTML.default
                      : CardEmptyJsx.default
                  }
                />
              </div>
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Cards are elements that display content and actions on a single
            topic.
          </p>
          <p>
            They should be easy to scan for relevant and actionable information.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Cards have no singular layout, typographic, or image size. They can
            include:
          </p>
          <ul>
            <li>
              <strong>Card header:</strong> the card header can include a title,
              subtitle and even a user avatar. The card header has inner padding
              of 1.5rem (24px).
            </li>
            <li>
              <strong>Card image:</strong> the card's image expands to the full
              width of the card (or half in horizontal cards).
            </li>
            <li>
              <strong>Card body:</strong> The main area of the card. It has
              inner padding of 1.5rem (24px). If the card's body is following
              the header the top padding is removed.
            </li>
            <li>
              <strong>Card action:</strong> This area includes a set of actions
              (links or buttons) and has horizontal padding of 0.5rem (8px) so
              that the button text is aligned with the card's body text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            There’s no accessibility standard to follow, no{" "}
            <code>&lt;card&gt;</code> element or an ARIA design pattern.
            Instead, the potential accessibility barriers you might encounter
            are depending on the card’s purpose and content.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
