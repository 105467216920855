import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function CardEmptyPreview(props) {
  const { CardEmpty } = props
  return (
    <>
      <CopyComponent copyToClipboardCode={CardEmpty} componentName="CardEmpty">
        {/* <!-- Component: Empty card --> */}
        <div className="w-64 h-40 overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200 md:h-72 md:w-96">
          <div className="p-6"></div>
        </div>
        {/* <!-- End Empty card --> */}
      </CopyComponent>
    </>
  )
}
