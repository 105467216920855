import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function CardSubtitlePreview(props) {
  const { CardSubtitle } = props

  return (
    <>
      <CopyComponent
        copyToClipboardCode={CardSubtitle}
        componentName="CardSubtitle"
      >
        {/*<!-- Component: Card with subtitle --> */}
        <div className="overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
          <div className="p-6">
            <header className="mb-4">
              <h3 className="text-xl font-medium text-slate-700">
                A place under the night sky
              </h3>
              <p className="text-sm text-slate-400">
                By George Johnson, jun3 28
              </p>
            </header>
            <p>
              There’s nothing better than hiking along the rocky footpaths,
              accompanied only by the noise of cicadas. As the boat docks in the
              harbor, gaze upon white and blue houses, craggy cliffs, sweeping
              olive groves, and the dazzling blues of the Aegean sea.
            </p>
          </div>
        </div>
        {/*<!-- End Card with subtitle --> */}
      </CopyComponent>
    </>
  )
}
